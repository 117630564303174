// filters
$.expr[':'].external = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return !obj.href.match(/^mailto\:/) && (obj.hostname != location.hostname);
        }
        return false;
    } catch (e) {
        return false;
    }
};
$.expr[':'].download = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return (obj.href.match(/\.(pdf|doc|docx|xls||xlsx|cvx|jpg|gif|mp3|swf|txt|ppt|zip|gz|dmg)$/) !==  null);
        }
        return false;
    } catch (e) {
        return false;
    }
};
$.expr[':'].image = function(obj) {
    'use strict';
    try {
        if (obj.href) {
            return (obj.href.match(/\.(jpg|png|jpeg|JPG|PNG)$/) !==  null);
        }
        return false;
    } catch (e) {
        return false;
    }
};

$(document).ready(function() {
    'use strict';
    // enllaços externs en finestra nova
    $('a:external, a[rel=external]').attr('target', '_blank').addClass('external').attr('rel', 'external');

});


autosize($('textarea.autosize'));

$("a.confirmDelete").click(function(event) {
    event.preventDefault();
    var url=$(this).attr('href');
    vex.dialog.confirm({
        message: 'Estàs segur que vols esborrar aquesta entrada?',
        buttons: [
            $.extend({}, vex.dialog.buttons.YES, {
                text: 'Esborrar'
            }), $.extend({}, vex.dialog.buttons.NO, {
                text: 'Cancel·lar'
            })
        ],
        callback: function(value) {
            if (value) {
                window.location = url
            }
        }
    });
});


if ($(".chzn-select").length > 0) {
	$(".chzn-select").chosen({ width:"100%",allow_single_deselect: true, no_results_text: "No hi ha resultats per " });
}


$("#createListModal").click(function(event) {
	var form = $(this).closest('form');

    event.preventDefault();
    var url=$(this).attr('href');
    vex.dialog.open({
        message: 'Introdueix el nom de la llista que vols crear',
        input: '<input name="listName" type="text" placeholder="Nom de la llista" required />',
        buttons: [
            $.extend({}, vex.dialog.buttons.YES, {
                text: 'Crear'
            }), $.extend({}, vex.dialog.buttons.NO, {
                text: 'Cancel·lar'
            })
        ],
        callback: function(value) {
        	if (!value) {

        	} else {
        		if (value.listName != '') {
            		form.append('<input type="hidden" name="listName" value="' + value.listName + '">');
            		form.submit();
        		}
        	}

        }
    });

    return false;
});
