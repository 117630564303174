


//inicialitza validator
if (jQuery.validator) {
	jQuery.validator.setDefaults({
		errorElement: 'div',
		ignore: [],
		errorPlacement: function(error, element) {
			var editor = element.parent().find('div.cke');
			var chosen = element.parent().find('div.chosen-container');
			if (editor.length > 0) {
				//error.css('display', 'inline-block').css('margin-top', '3px').insertAfter(editor);
				error.insertAfter(editor);
			} else if (chosen.length > 0) {
				//error.css('display', 'inline-block').css('margin-top', '3px').insertAfter(chosen);
				error.insertAfter(chosen);
			} else if (element.is('[type=radio]') || element.is('[type=checkbox]')) {
				error.insertAfter(element.closest('label'));
			} else {
				error.insertAfter(element);
			}
		},
	    highlight: function(element) {
	        $(element).closest('.form-group').addClass('has-error');
	    },
	    unhighlight: function(element) {
	        $(element).closest('.form-group').removeClass('has-error');
	    }
	});

	//missatge sota el input
	$('form').each(function(){
		$(this).validate();
	});
}


/*    
$('table.dataTable').each(function( index ) {
    var paginate = true;
    if ($(this).find('tr').length <= 10) {
        paginate = false;
    }
    var scroll = false;

    if ($(this).data('scroll')) {
        scroll = true;
        //$(this).find('th, tr').css('white-space', 'nowrap');
    }

    $(this).DataTable({
    	"language": {
    	    "decimal":        "",
    	    "emptyTable":     "No hi ha resultats",
    	    "info":           "Mostrant de _START_ a _END_ de _TOTAL_ registres",
    	    "infoEmpty":      "Mostrant 0 registres",
    	    "infoFiltered":   "(filtered from _MAX_ total entries)",
    	    "infoPostFix":    "",
    	    "thousands":      ",",
    	    "lengthMenu":     "Mostra _MENU_ registres",
    	    "loadingRecords": "Carregant...",
    	    "processing":     "Processant...",
    	    "search":         "Cerca ",
    	    "zeroRecords":    "No s'han trobat resultats",
    	    "paginate": {
    	        "first":      "Primer",
    	        "last":       "Últim",
    	        "next":       "Següent",
    	        "previous":   "Anterior"
    	    },
    	    "aria": {
    	        "sortAscending":  ": activa per ordenar aquesta columna de forma ascendent",
    	        "sortDescending": ": activa per ordenar aquesta columna de forma descendent"
    	    }
    	},
        columnDefs: [
                     { type: 'natural', targets: 0 }
                   ],
        "bPaginate": paginate,
        "bInfo": paginate,
        "scrollX": scroll,
        dom:
            "<'row'<'col-sm-6'i><'col-sm-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'l><'col-sm-7'p>>",
        stateSave: true,
        stateDuration: 60 * 60,
        initComplete: function () {
            this.api().columns().every( function (i) {
                var column = this;
                if ($(column.header()).data('select-search') == true) {
                    info = column.data().map(function(obj){
                        if ($(obj).text()) {
                            return $(obj).text();
                        } else {
                            return obj;
                        }
                    });
                    info = info.unique().sort();

                    if (info.length > 1) {
                        var search = $(column.header()).closest('.dataTables_wrapper').find('.dataTables_filter');
                        var select = $('<select class="form-control input-sm"><option value=""></option></select>')
                            .appendTo( search )
                            .on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );
                                column
                                    .search( val ? '^'+val+'$' : '', true, false )
                                    .draw();
                            } );
                        info.each( function ( d, j ) {
                            //TODO: quant no hi ha html falla al map
                            //TODO: no manté el select al canviar de pàgina
                            select.append( '<option value="'+d+'">'+d+'</option>' )
                        } );
                    }



                }
            } );
        }
    });

    
    
    
    
});
*/   




/*


$('.multiFields').each(function( index ) {
	var multi = $(this);
	var newField = multi.find('ol').data('new');
	var next = multi.find('ol').data('next');

	multi.find('ol').sortable({
		handle: ".move",
        stop: function () {
            $(this).find('input.order').each(function(idx) {
                $(this).val(idx);
            });
        }
	});
	multi.find('ol li a.delete').on('click', function(event){
		event.preventDefault();
		$(this).closest('li').remove();
	});

	multi.find('a.add').bind('click', function(event){
		console.log('hola');
		event.preventDefault();
		var ol = $(this).closest('div').find('ol');
		ol.append(newField.replace(/\[id\]/g, '['+next+']'));
        ol.find('input.order').each(function(idx) {
            $(this).val(idx);
        });

		ol.find('li:last a.delete').on('click', function(event){
			event.preventDefault();
			$(this).closest('li').remove();
		});
		next++;
		return false;
	});

});
*/